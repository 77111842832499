
              @import "@/assets/css/variables.scss";
            
































































































.verification-code-container {
  display: flex;
  .verification-code {
    @include rtl-sass-prop(margin-right, margin-left, 1rem);
  }
  .el-button {
    margin-top: 22px;
  }
}

@media (max-width: 630px) {
  .verification-code-container {
    flex-direction: column;
    .verification-code {
      margin-right: 0;
    }
    .el-button {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }
}
